export const state = () => ({
    windowWidth: 0,
    scrollY: 0,
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
};

export const actions = {
    async fetchEssentialData(context) {
        await Promise.allSettled([
            this.$axios.$get('/page-metadata'),
            this.$axios.$get('/menus'),
            this.$axios.$get('/redirects'),
            this.$axios.$get('/qr-codes'),
            this.$axios.$get('/events/categories/event-categories'),
            this.$axios.$get('/events/categories/event-types'),
            this.$axios.$get('/events/locations/event-locations'),
            this.$axios.$get(`/events?highlighted=1&start_date=${this.$dayjs().format('YYYY-MM-DD')}&limit=7`),
        ])
            .then(
                ([
                    metaRes,
                    menuZonesRes,
                    redirectsRes,
                    qrCodeRedirectRes,
                    eventCategoriesRes,
                    eventTypesRes,
                    eventLocationsRes,
                    recommendedEventsRes,
                ]) => {
                    if (metaRes.status === 'fulfilled') {
                        context.commit('data/setMetaData', {
                            data: metaRes.value,
                        });
                    }

                    if (menuZonesRes.status === 'fulfilled') {
                        context.commit('data/setMenuZones', {
                            data: menuZonesRes.value,
                        });
                    }

                    if (redirectsRes.status === 'fulfilled') {
                        context.commit('data/setRedirects', {
                            data: redirectsRes.value.data,
                        });
                    }

                    if (qrCodeRedirectRes.status === 'fulfilled') {
                        context.commit('data/setQrCodeRedirects', {
                            data: qrCodeRedirectRes.value.data,
                        });
                    }

                    if (eventCategoriesRes.status === 'fulfilled') {
                        context.commit('data/setEventCategories', {
                            data: eventCategoriesRes.value,
                        });
                    }

                    if (eventTypesRes.status === 'fulfilled') {
                        context.commit('data/setEventTypesByCategory', {
                            data: eventTypesRes.value,
                        });
                    }

                    if (eventLocationsRes.status === 'fulfilled') {
                        context.commit('data/setEventLocations', {
                            data: eventLocationsRes.value,
                        });
                    }

                    if (recommendedEventsRes.status === 'fulfilled') {
                        context.commit('data/setRecommendedEvents', {
                            data: recommendedEventsRes.value.data,
                        });
                    }
                }
            )
            .catch((err) => {
                console.log(err);
            });
    },
    async nuxtServerInit({ dispatch }, { req }) {
        await dispatch('fetchEssentialData');
    },
};
