import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80997234 = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _0c4a6abe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4c6d62aa = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _5e0261b8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0ca0577a = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _0309b60b = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _0e08aeb2 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3d4162d5 = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _111f3d76 = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _5c384221 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _1876606c = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _748929a1 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _3b4704c2 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _16be2901 = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _55219125 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _478e5e62 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _2877ca14 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _9818a222 = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _387e5712 = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _2b9ac078 = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _60e15201 = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _fc70ddf0 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _2117cbe5 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _61314927 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _0cd564c5 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _f5a46af0 = () => interopDefault(import('../pages/top-locations/city-park-visitor-center.vue' /* webpackChunkName: "pages/top-locations/city-park-visitor-center" */))
const _66859e05 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _7abf234d = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _5e0567f2 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _c30bb724 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _044252ee = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _fc29df10 = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _4ba4577e = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _4529a1e8 = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _9c7fb890 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _e30c6fc4 = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _46529a91 = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _8831996a = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _1c743d14 = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _dae47ebe = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _5c4bc070 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _80997234,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _0c4a6abe,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _4c6d62aa,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _5e0261b8,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _0ca0577a,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _0309b60b,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _0e08aeb2,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _3d4162d5,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _111f3d76,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _5c384221,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _1876606c,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _748929a1,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _3b4704c2,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _16be2901,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _55219125,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _4c6d62aa,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _16be2901,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _0309b60b,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _0ca0577a,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _3d4162d5,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _5c384221,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _3b4704c2,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _1876606c,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _0c4a6abe,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _55219125,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _0e08aeb2,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _748929a1,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _80997234,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _111f3d76,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _478e5e62,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _2877ca14,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _9818a222,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _387e5712,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _2b9ac078,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _60e15201,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _fc70ddf0,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _2117cbe5,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _61314927,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _0cd564c5,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosliget-latogatokozpont",
    component: _f5a46af0,
    name: "top-locations-city-park-visitor-center___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _66859e05,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _7abf234d,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _9818a222,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _478e5e62,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _2877ca14,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _387e5712,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _2b9ac078,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/city-park-visitor-center",
    component: _f5a46af0,
    name: "top-locations-city-park-visitor-center___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _60e15201,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _fc70ddf0,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _2117cbe5,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _61314927,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _0cd564c5,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _66859e05,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _7abf234d,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _5e0567f2,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _c30bb724,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _044252ee,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _fc29df10,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _4ba4577e,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _4529a1e8,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _9c7fb890,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _e30c6fc4,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _46529a91,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _8831996a,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _1c743d14,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _dae47ebe,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _5e0567f2,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _c30bb724,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _044252ee,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _5c4bc070,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _4ba4577e,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _4529a1e8,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _dae47ebe,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _9c7fb890,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _8831996a,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _46529a91,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _1c743d14,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _e30c6fc4,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _fc29df10,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _5e0261b8,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _5c4bc070,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
