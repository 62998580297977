export default ({ store, $axios, $dayjs }) => {
    Promise.allSettled([
        $axios.$get('/public-announcements'),
        $axios.$get('/news-categories'),
        $axios.$get('/static-images'),
        $axios.$get('/balloon'),
        $axios.$get('/news?is_highlighted=1&limit=3'),
    ])
        .then(
            ([publicAnnouncementsRes, newsCategoriesRes, staticImagesRes, balloonRes, homepageHighlightedNewsRes]) => {
                if (publicAnnouncementsRes.status === 'fulfilled') {
                    store.commit('data/setPublicAnnouncements', {
                        data: publicAnnouncementsRes.value.data,
                    });
                }

                if (newsCategoriesRes.status === 'fulfilled') {
                    store.commit('data/setNewsCategories', {
                        data: newsCategoriesRes.value.data,
                    });
                }

                if (staticImagesRes.status === 'fulfilled') {
                    store.commit('data/setStaticImages', {
                        data: staticImagesRes.value,
                    });
                }

                if (balloonRes.status === 'fulfilled') {
                    store.commit('data/setBalloonStatus', {
                        data: balloonRes.value.balloon_is_up,
                    });
                }

                if (homepageHighlightedNewsRes.status === 'fulfilled') {
                    store.commit('data/setHomepageHighlightedNews', {
                        data: homepageHighlightedNewsRes.value.data,
                    });
                }
            }
        )
        .catch((err) => {
            console.log(err);
        });
};
