import dev from './development/hu.json';
const merge = require('deepmerge');

export default async ({ $axios, $cookies, $config }) => {
    const options = {};

    if ($config.nuxtENV !== 'development') {
        options.baseURL = '';
    }

    return await $axios
        .$get(`/translations/hu?nocache=${$cookies.get('show_translation_keys') || ''}`, options)
        .then((res) => {
            return merge(dev, res);
        });
};
